import { SET_FONTLIST } from '../actions';

const fontlist = (state = [], action) => {
  switch (action.type) {
	case SET_FONTLIST:
      return [...action.value];  
    default: return state;
  }
}

export default fontlist;
