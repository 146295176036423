import {SET_ADDPACKAGE, SET_ACTIVATEPACKAGE, SET_PACKAGEDETAIL, SET_EDITPACKAGE, SET_PACKAGELIST } from '../actions';

export const packageadd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDPACKAGE:
      return [...action.value];  
    default: return state;
  }
}

export const packageactive = (state = [], action) => {
  switch (action.type) {
	case SET_ACTIVATEPACKAGE:
      return [...action.value];  
    default: return state;
  }
}


export const packagedetail = (state = [], action) => {
  switch (action.type) {
	case SET_PACKAGEDETAIL:
      return [...action.value];  
    default: return state;
  }
}

export const packageedit = (state = [], action) => {
  switch (action.type) {
	case SET_EDITPACKAGE:
      return [...action.value];  
    default: return state;
  }
}


export const packagelist = (state = [], action) => {
  switch (action.type) {
	case SET_PACKAGELIST:
      return [...action.value];  
    default: return state;
  }
}
