import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';

class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const LoanConfigId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		
		this.state = {
			above_revenue_amount:'',
			revenue_rating:'',
			c_loanconfig_id:LoanConfigId,
			
			
			Loading:false
		};
	
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		//console.log(this.state.c_company_id+"-test");
		
		var qs = require('qs');
		var loanconfigId = this.state.c_loanconfig_id;

		axios.get(apiUrl+"annualRevenueconfig/listdetail?id="+loanconfigId).then(res => {
			const formdata = res.data.loanconfiglist;
			if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});


			}
					
			this.setState({above_revenue_amount: formdata.above_revenue_amount});
			this.setState({revenue_rating: formdata.revenue_rating});
			this.setState({status: formdata.selectedOption});
		}); 

    }
	

    // handleChange = (v, action) => {
	// 	console.log(v,"name_type");
	// 	console.log(action,"action");
    //     this.setState({  [action] : v.value});
    // };

	handleChange = selectedOption => {
        this.setState({selectedOption});
		this.setState({  selectedvalue : selectedOption.value});

    };

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Rating Configuration Edit');
    }

        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				let status;
				const amount = formPayload.above_revenue_amount;
				status =formPayload.selectedOption.value;
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					amount:amount,
					revenue_rating:formPayload.revenue_rating,
					loanconfigId: this.state.c_loanconfig_id,
					status: formPayload.selectedvalue
				};
				console.log(postObject, 'post_obj')			;
				
				axios.post(apiUrl+"annualRevenueconfig/edit",qs.stringify(postObject)).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });

						const formpayload = res.data.loanconfiglist;
						if(formpayload.status === 'Active'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			
						}
						this.setState({above_revenue_amount: formpayload.above_revenue_amount});
						this.setState({revenue_rating: formpayload.revenue_rating});
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}
	handlePriceChange = (e) => {
		const inputValue = e.target.value;
	
		// You can format the input value with a currency symbol here
		if(inputValue!=''){
		const formattedPrice = inputValue.replace(/[^\d.]/g, '');

	    const priceWithSymbol = `$${formattedPrice}`;
	   

	
		this.setState({
			amount: priceWithSymbol
		  });
		}
	  };
	  validateForm() {
		const {above_revenue_amount,revenue_rating} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!revenue_rating) {
			formIsValid = false;
			$('.errorrevenue_rating').html('<span class="errorspan">Please fill the field</span>');
		}else if(revenue_rating){
			$('.errorrevenue_rating').html('');
		}
		
				
		if (!above_revenue_amount) {
			formIsValid = false;
			$('.errorabove_revenue_amount').html('<span class="errorspan">Please fill the field</span>');
		}else if(above_revenue_amount){
			if (/^\$?[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/.test(above_revenue_amount)) {

				$('.errorabove_revenue_amount').html('');
			  }else{
				formIsValid = false;
			$('.errorabove_revenue_amount').html('<span class="errorspan">Please enter the valid amount</span>');
			  }

			
		}

		



		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(){

		
    }
	
  render() {
  	  const {selectedOption} = this.state;
	  console.log(selectedOption, 'sel_opt');
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="annualconfiguration" />  

	
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
		<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			 <div className="form-row">
			<div className="form-left">
				
				
				<div className="form-group">
					<label>Annual Amount<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="number"  name="above_revenue_amount" onChange={this.handleInputChange} className="form-control" value={this.state.above_revenue_amount} />
					<div className="errorabove_revenue_amount"></div>
				</div>
				<div className="default-font">
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span></label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			</div>
			<div className="form-right">	
				
				<div className="form-group">					
					<label>Rating<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="text" name="revenue_rating" onChange={this.handleInputChange} className="form-control" value={this.state.revenue_rating} />
					<div className="errorrevenue_rating"></div>
				</div>

				
			</div>	
			</div>				

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Edit));