import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import { PageTitle, CheckAuth, scrollToTopValidate } from "../Helpers/SettingHelper";
import { sampleFileUrl} from'../Config/Config';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';

class Import extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			
			type:'',
		    selectedFile: null,
			Loading:false,
			selectedOption: "",
		};
		  
	   this.handleInputChange = this.handleInputChange.bind(this);
    }
	
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	handleChange = (r,selOption) => {
       
		this.setState({selectedOption:selOption})
		this.setState({  selectedvalue : selOption.value});
		
 	};

	componentDidMount() {
		document.title = PageTitle('Import');
    }

  onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
     
    }; 

	 handleFormSubmit = () => {
		
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var imp_cate = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				imp_cate =formPayload.selectedOption.value;
				}else{
				imp_cate = formPayload.selectedvalue;
				}

				var admin_id = localStorage.getItem("admin_id");
				var postObject = {
					admin_id     : admin_id,
					selectedFile : formPayload.selectedFile,
					type       	: formPayload.type,
					imp_cate	: imp_cate
				};
				
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
				console.log(formPayload, 'formPayload');

				axios.post(apiUrl+"faq/import_aifeed",formData ).then(res => {
					if(res.data.status === "success"){
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
							function() {
								$('.success_message').html(this.props.history.push('/aifeed'));
							}
							.bind(this),
							3000
						);
					}else if(res.data.status === "error"){
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						this.setState({Loading:false});
					}
				});
			}
	}

	validateForm() {

		const {selectedFile} = this.state;

		let errors = 0;

		if (selectedFile === null || selectedFile === '') {
		$('.errorfile').html('<span class="errorspan">Please choose the file</span>');
		errors++;
		}else if(selectedFile){
	    var filePath = selectedFile.name;
	    var allowedExtensions =  
                    /(\.csv)$/i; 
        if(!allowedExtensions.exec(filePath)){
        	errors++;
		    $('.errorfile').html('<span class="errorspan">Please choose only csv format</span>');
        }else{
            $('.errorfile').html('');
        } 
        }else{             
		$('.errorfile').html('');
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }
   
  render() {
	  var FileUrl = sampleFileUrl+"Sampleaifeed.csv";

  	const {selectedOption} = this.state;


    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="Aifeed" />  

	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">

			<div className="success_message"></div>
			<div className="title">
				<h4>Import</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row import-user-record">
			<div className="form-left">		

				<div className="form-group">					
					<label>Type</label>
						   <input type="radio" name='type' value="text" onChange={this.handleInputChange}/> Text 
						   <input type="radio" name='type' value="chat" onChange={this.handleInputChange}/> Chat 
	           </div>     

			   <div className="form-group">					
					<label>Category</label>
						   <Select 
						   value={selectedOption?selectedOption:""}
                            options={lang.common.categories_option} 
                            onChange={this.handleChange.bind(this, 'ins_cate')}
                            />
	           </div>     	
			
               <div className="form-group">
				 <label>Upload(csv)<span class="required" style={{ color: "red" }} > * </span>  </label>
				 <input type="file" onChange={this.onFileChange} /> 
                <div className="errorfile"></div> 
                </div>				
				
			</div>				

			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			<div className="download">
	        <a className="" href={FileUrl} target="_blank" download >Sample File <i class="fa fa-download" aria-hidden="true"></i></a>
            </div>
			</div>
  
		
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default (withRouter(Import));