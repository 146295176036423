import React, { Component } from 'react';

class Files extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
		};
		
    }
	
	
	
  render() {
    return (
			<div className="form-wrapper">
			<div className="title">
				<h4>Setting</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>Name</label>
					<input type="text" className="form-control" value="John Doe" />
				</div>
				<div class="form-group">					
					<label>Tel Number</label>
					<input type="text" className="form-control" value="9123 4567" />
				</div>
				<div class="form-group">
					<label>Address</label>
					<input type="text" className="form-control" value="60, Bukit Timah Road, S123456" />
				</div>
			</div>
			<div className="form-right">
				<div class="form-group">					
					<label>Email</label>
					<input type="text" className="form-control"  value="test@gmail.com" />
				</div>
				<div class="form-group"> 
					<label>D.O.B</label>
					<input type="text" className="form-control"  placeholder="" />
				</div>
				<div class="form-group">
					<label>Occupation</label>
					<input type="text" className="form-control" value="Construction Engineer" />
				</div>
			</div>
			</div>		

			<div className="btn-group export">	
				<input type="submit" className="btn" value="Submit" />
			</div>

			</div>
    );
  }
}

export default Files;