import { SET_FONTNAME } from '../actions';

const fontname = (state = [], action) => {
  switch (action.type) {
	case SET_FONTNAME:
      return [...action.value];  
    default: return state;
  }
}

export default fontname;
