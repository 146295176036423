import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import {
  Table
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';

import { GET_LOANCONFIGLIST  } from '../../actions';
import parse from 'html-react-parser';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import Pagination from "react-js-pagination";

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      loanconfiglist:'',
      activePage: 1,
          totalRecord: '',
          search_all:'',
          sno_index:0,
          
    };
    //this.props.getloanconfiglist();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   
   componentDidMount() {
    var qs = require('qs');
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: localStorage.getItem('admin_id')  
    };
   this.configlistload(postobject);
 }

 handleFormSubmit = () => {
      
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: localStorage.getItem('admin_id')           
  };
  //console.log(postObject);
   this.configlistload(postObject);

} 
handleInputChange(event) {
	  const {name, value} = event.target;      
	  this.setState({
		[name]: value
	  });
    var postobject = {
      activePage: 1,
      search_all: value,
      admin_id: localStorage.getItem('admin_id')  
    };
    this.configlistload(postobject);
	}
configlistload = (postobject) =>{

  var qs = require('qs');
  axios.post(apiUrl+"annualRevenueconfig/list",qs.stringify(postobject)).then(res => {	   
   
    this.setState({ totalRecord : res.data.records_count, loanconfiglist: res.data.loanconfiglist }, function() {
           this.loanconfiglist();
    });
 });
}
handlePageChange(pageNumber) {
  // console.log(`active page is ${pageNumber}`);
   this.setState({activePage: pageNumber});
   var sno_index = pageNumber - 1;
   this.setState({sno_index: sno_index});

   var qs = require('qs');
   var postobject = {
     activePage: pageNumber,
     search_all: this.state.search_all,
     admin_id: localStorage.getItem('admin_id')  
   };
 
 this.configlistload(postobject);
 }
   componentWillReceiveProps(Props){

   /* if (Object.keys(Props.loanconfiglist).length > 0) {
      if (Props.loanconfiglist[0]["status"] === "success") {
        this.setState({
          loanconfiglist: Props.loanconfiglist[0]["loanconfiglist"],
        });
      }
    }*/
     
   }
   deleteloanconfig(){
   
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :this.state.selectid,
     
    };

  axios.post(apiUrl+"annualRevenueconfig/delete",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
      var postobject1 = {
        activePage: this.state.activePage,
        search_all: '',
        admin_id : localStorage.getItem("admin_id")
      };
      this.configlistload(postobject1)

     
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
      setTimeout(
        function() {
       
          $('.success_message').html('');
           
        }
        .bind(this),
        3000
        );
        
  }
  }); 
}
  
  loanconfiglist() {

   // var loanconfiglist = this.props.loanconfiglist[0];
    var loanconfiglistIS = this.state.loanconfiglist;
	console.log(loanconfiglistIS,'loanconfiglistIS');
	
    if (loanconfiglistIS != "undefined" && loanconfiglistIS != null) {
      
        if (Object.keys(loanconfiglistIS).length > 0) {
          const loanconfiglistDetails = loanconfiglistIS.map(
            (loanconfiglist, Index) => {
              let sno = Index+1;
              // if(loanconfiglist.admin_status == 'Yes'){
              //   var status = 'Active';
              // }else{
              //     status = 'Inactive';
              // }
              var domainlist = loanconfiglist.domains;
              let domvalu ='';
                      
              return (
                <tr key={loanconfiglist.id}>
                  <td>{sno}</td>
                                 
                  <td>${loanconfiglist.above_revenue_amount}</td>

                  <td>
                  {loanconfiglist.revenue_rating}
                  </td> 
                  {/* <td>{status}</td> */}
                  <td className="actiongroup">
                    <Link to={{ pathname: '/editannualconfiguration/'+loanconfiglist.id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                    <Link onClick={this.confirmPopup.bind(this,loanconfiglist.id,"delete")} className="" title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return loanconfiglistDetails;
        }
      
    } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
  }
  confirmPopup = (id, status) => {
    var status = status;
    if(status == 'inactive'){
      status = "active";
    }else if(status == 'active'){
      status = "inactive";
    }else{
      status="delete";
    }
    this.setState({selectid: id, selectedStatus: status})	
    $('.confirm-action').addClass('show');		
  }
  
  Confirmstatus = (confirmstatus) => {
    if(confirmstatus == 'yes'){
      if(this.state.selectedStatus=="delete"){
        
        this.deleteloanconfig()
      }
     
      $('.confirm-action').removeClass('show');
    }else{
      $('.confirm-action').removeClass('show');	
    }
      
  }

  
	
  render() {
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the Rating config?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="annualconfiguration" />  

  
  <div className="content"> 
    <div className="content-wrapper">
          <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
  
        <div className="listing-header"> 
          <div className="title">
            <h3>Rating Configuration</h3>
          </div> 
          <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
              <a className="add" href='/annualconfiguration-add'>Add</a> 
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
				<th>Revenue Amount</th>
				<th>Rating</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.loanconfiglist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    loanconfiglist: state.loanconfiglist,
   // deletecompany: state.deletecompany

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getloanconfiglist: () =>{
       dispatch({ type: GET_LOANCONFIGLIST });
    },
   
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));