import { SET_ACTIVATECMS } from '../actions';

const cmsactive = (state = [], action) => {
  switch (action.type) {
	case SET_ACTIVATECMS:
      return [...action.value];  
    default: return state;
  }
}

export default cmsactive;
