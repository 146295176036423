import { SET_TEMPLATEEDIT } from '../actions';

const templateedit = (state = [], action) => {
  switch (action.type) {
	case SET_TEMPLATEEDIT:
      return [...action.value];  
    default: return state;
  }
}

export default templateedit;
