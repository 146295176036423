/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_FONTDETAILLIST, SET_FONTDETAILLIST ,GET_EDITFONT,SET_EDITFONT,GET_FONTLIST, SET_FONTLIST, GET_FONTNAME, SET_FONTNAME, GET_ADDFONT, SET_ADDFONT,GET_DELETEFONT,SET_DELETEFONT} from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


/*list*/



export const watchGetFontList = function* () {
  yield takeEvery(GET_FONTLIST, workerGetFontList);
}

function* workerGetFontList({ formVal }) {
  try {
      
 const result = yield call(getFontList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_FONTLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getFontList(formVal) {
   return Axios.post(apiUrl+'font/list', formVal);
} 


/*export const watchGetFontList = function* () {
  yield takeEvery(GET_FONTLIST, workerGetFontList);
}

function* workerGetFontList({ pluginId }) {
  if(pluginId == undefined){
    console.log('123')
      var url = apiUrl+'font/list';
  }else{
      var url = apiUrl+'font/list?id='+pluginId;
  }
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FONTLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
} */



export const watchGetFontName = function* () {
  yield takeEvery(GET_FONTNAME, workerGetFontName);
}

function* workerGetFontName() {
      var url = apiUrl+'font/getfont';
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FONTNAME, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}


/*save the font*/

export const watchGetAddFont = function* () {
  yield takeEvery(GET_ADDFONT, workerGetAddFont);
}

function* workerGetAddFont({ formPayload }) {
  try {
      
 const result = yield call(getAddFont, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDFONT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddFont(formPayload) {
   return Axios.post(apiUrl+'font/add', formPayload);
} 


/*font delete*/

/*delete the car*/

export const watchGetDeleteFont = function* () {
  yield takeEvery(GET_DELETEFONT, workerGetDeleteFont);
}

function* workerGetDeleteFont({ formPayload }) {
  try {
      
 const result = yield call(getDeleteFont, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEFONT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteFont(formPayload) {
   return Axios.post(apiUrl+'font/delete', formPayload);
}



export const watchGetFontDetailList = function* () {
  yield takeEvery(GET_FONTDETAILLIST, workerGetFontDetailList);
}

function* workerGetFontDetailList({ FontId }) {
    var url = apiUrl+'font/listdetail?id='+FontId;
    try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data)
    yield put({ type: SET_FONTDETAILLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
} 



/*edit the company*/

export const watchGetEditFont = function* () {
  yield takeEvery(GET_EDITFONT, workerGetEditFont);
}

function* workerGetEditFont({ formPayload }) {
  try {
      
 const result = yield call(getEditFont, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITFONT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditFont(formPayload) {
   return Axios.post(apiUrl+'font/edit', formPayload);
}