import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { carImageUrl } from '../Config/Config';
import { apiUrl, categoryURL} from'../Config/Config';
import axios from 'axios';


class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
		const lenderid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

		this.state = {
			lenderid: lenderid,
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			company_name:'',
			year_of_started:'',
			phoneno:'',
			password:'',
			status:'',
			changepassword:'',
			user_confirm_password: '',
			 new_pass_type: 'password',
			confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedYear:'',
			selectedTIB:{ value: '6', label: 'Less than 6 months' },
			selectedvalue:'',
			selectedTIBvalue:'',
			selectedYearvalue:'',
			disablepasssword:true,
			previous_funded:'',
			website:'',
			rating:'',
			review:'',
			address:''	
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	  // this.props.getUserDetailList(lenderid);
    }

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      /*if(name == 'password'){
      	this.setState({disablepasssword: false})
      }*/
    }
    handleChange = (r, selOption) => {

		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}

		if(r == 'tib'){
			this.setState({selectedTIB:selOption})
			this.setState({  selectedTIBvalue : selOption.value});
		}

		if(r == 'year_of_started'){
			this.setState({selectedYear:selOption})
			this.setState({  selectedYearvalue : selOption.value});
		}
		
    };
	componentDidMount() {
		document.title = PageTitle('Lender Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
		
		
		axios.get(apiUrl+'lender/listdetail?id='+this.state.lenderid)
		.then(res => {
				if(res.data.status == 'success'){
					const formdata = res.data.userlist;						
					if(formdata.status === 'A'){
						this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
						this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
					}
					this.setState({username:formdata.username});
					this.setState({firstname:formdata.firstname});
					this.setState({previous_funded:formdata.previous_funded});					
					this.setState({website:formdata.lender_domain});					
					this.setState({rating:formdata.rating});					
					this.setState({review:formdata.review});					
					this.setState({review:formdata.review});					
					this.setState({review:formdata.review});					
					this.setState({review:formdata.review});					
					this.setState({review:formdata.review});					
					this.setState({lastname:formdata.lastname});
					this.setState({email:formdata.email});
					this.setState({company_name:formdata.company_name});
					this.setState({phoneno:formdata.mobileno});
					this.setState({lenderid: formdata.id});
					this.setState({address:formdata.address});

					if(formdata.time_in_business === '6'){
						this.setState({selectedTIB:{value: '6', label: 'Less than 6 months'}});
					}else if(formdata.time_in_business === '12'){
						this.setState({selectedTIB:{value: '12', label: '6-12 months'}});
					}else if(formdata.time_in_business === '24'){
						this.setState({selectedTIB:{value: '24', label: '1 year'}});
					}else if(formdata.time_in_business === '60'){
						this.setState({selectedTIB:{value: '60', label: '2-3 years'}});
					}else if(formdata.time_in_business === '90'){
						this.setState({selectedTIB:{value: '90', label: '3-5 years'}});
					}else if(formdata.time_in_business === '120'){
						this.setState({selectedTIB:{value: '120', label: 'More than 5 years'}});
					}

					if(formdata.year_of_started !== ''){
						this.setState({selectedYear:{value: formdata.year_of_started, label: formdata.year_of_started}});
					}else{
						this.setState({selectedYear:''});
					}
				
				}else{
				//console.log("test")
				}
			});
		
    }

	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
	
	
 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status,time_in_business,year_of_started = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.selectedTIBvalue === '' && formPayload.selectedTIBvalue!== 0){
					time_in_business = formPayload.selectedTIB.value;
				}else{
					time_in_business = formPayload.selectedTIBvalue;
				}

				if(formPayload.selectedYearvalue === '' && formPayload.selectedYearvalue!== 0){
					year_of_started = formPayload.selectedYear.value;
				}else{
					year_of_started = formPayload.selectedYearvalue;
				}

				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					lenderid       : formPayload.lenderid,
					username     : formPayload.username,
					firstname    : formPayload.firstname,
					previous_funded : formPayload.previous_funded,
					website : formPayload.website,
					rating : formPayload.rating,
					review : formPayload.review,
					lastname     : formPayload.lastname,
					email        : formPayload.email,
					year_of_started: year_of_started,
					company_name : formPayload.company_name,
					phoneno      : formPayload.phoneno,
					time_in_business : time_in_business,
					address : formPayload.address,
					/*currentpswd  : formPayload.password,*/
					changepassword:formPayload.changepassword, 
					status       :  status,
				};
				//this.props.getEditUser(qs.stringify(postObject));
				axios.post(apiUrl+"lender/edit",qs.stringify(postObject)).then(res => {	  
					if(res.data.status === "success") {

						const formdata = res.data.userlist;
						console.log(formdata);
						if(formdata.status === 'A'){
						this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
						this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
						}

						if(formdata.year_of_started !== ''){
							this.setState({selectedYear:{value: formdata.year_of_started, label: formdata.year_of_started}});
						}else{
							this.setState({selectedYear:''});
						}

						this.setState({username:formdata.username});
						this.setState({firstname:formdata.firstname});
						this.setState({previous_funded:formdata.previous_funded});
						this.setState({website:formdata.lender_domain});
						this.setState({rating:formdata.rating});					
					this.setState({review:formdata.review});
						this.setState({lastname:formdata.lastname});
						this.setState({email:formdata.email});
						this.setState({company_name:formdata.company_name});						
						this.setState({phoneno:formdata.mobileno});
						this.setState({lenderid: formdata.id});
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							scrollToTop();
							setTimeout(
								function() {
									$('.success_message').html('');
								}
								.bind(this),
								3000
								);
						} else{
							 $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							scrollToTop();
							setTimeout(
								function() {
									$('.success_message').html('');
								}
								.bind(this),
								3000
								);
						}
				 });
			}
	}
	 isValidWebsite(website) {
		// alert()
		 var urlRegex = /^(ftp|http|https|www):\/\/[^ "]+$/;
	    // var urlRegex = /^(ftp|http|https):\/\/(www\.)?[^ "]+$/;

		if (urlRegex.test(website)) {
			return true; // Valid website URL
		} else {
			return false; // Invalid website URL
		}
	}
	validateForm() {
		const {username,firstname, lastname, email, selectedYear, company_name, phoneno, changepassword, user_confirm_password, selectedTIB, address,rating,website} = this.state;
      	//let formIsValid = true;
		let errors = 0;
		const nameRegex = /^[A-Za-z\s.]+$/;

		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			if (!nameRegex.test(firstname)) {
				errors++;
			$('.errorfirstname').html('<span class="errorspan">Please enter the valid first name</span>');
			}else{
				$('.errorfirstname').html('');
			}
			
		}

		 if(lastname){
			if (!nameRegex.test(lastname)) {
				errors++;
			$('.errorlastname').html('<span class="errorspan">Please enter the valid last name</span>');
			}else{
				$('.errorlastname').html('');
			}
			
		}
		
		if (!email) {
			errors++;
			
			$('.erroremail').html('<span class="errorspan">Email is required</span>');
			}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
			  $('.erroremail').html('');
			}else{
			  errors++;
			  
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		

		if (!phoneno) {
			errors++;
			
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
				
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}


		


		if (!company_name) {
			errors++;
			$('.errorcompany_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(company_name){
			$('.errorcompany_name').html('');
		}

		// console.log(selectedYear, 'selectedYear');
		// if (!selectedYear) {
		// 	errors++;
		// 	$('.erroryear_of_started').html('<span class="errorspan">Please fill the field</span>');
		// }else if(selectedYear){
		// 	$('.erroryear_of_started').html('');
		// }

		// if (!selectedTIB) {
		// 	errors++;
		// 	$('.errortib').html('<span class="errorspan">Please fill the field</span>');
		// }else if(username){
		// 	$('.errortib').html('');
		// }

		if (!address) {
			errors++;
			$('.erroraddress').html('<span class="errorspan">Please fill the field</span>');
		}else if(address){
			$('.erroraddress').html('');
		}
		if (rating) {
			if (rating > 5) {
				errors++;
				$('.errorrating').html('<span class="errorspan">Rating must be less than or equal to 5</span>');
			} else {
				$('.errorrating').html('');
			}
		}
		if (website) {
			if (this.isValidWebsite(website)) {
				$('.errorwebsite').html('');

			} else {
				errors++;
				$('.errorwebsite').html('<span class="errorspan">Please enter valid website url</span>');
			}
		}
		
		
		// if(changepassword !=''){
		// 	if(user_confirm_password==''){
		// 		errors++;
		// 		$('.errorchangepaswd').html('Confirm password is required');
		// 	}
		//  else if (changepassword.match(/[a-z]/g) && changepassword.match( 
        //             /[A-Z]/g) && changepassword.match( 
        //             /[0-9]/g) && changepassword.match( 
        //             /[^a-zA-Z\d]/g) && changepassword.length >= 8){
		//  				$('.errorpassword').html('');
		//  }else{
		//  	errors++;
		// 	$('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		//  }
		// }
		
		// if(user_confirm_password !=''){
		//  if (user_confirm_password.match(/[a-z]/g) && user_confirm_password.match( 
        //             /[A-Z]/g) && user_confirm_password.match( 
        //             /[0-9]/g) && user_confirm_password.match( 
        //             /[^a-zA-Z\d]/g) && user_confirm_password.length >= 8){
		//  				$('.errorchangepaswd').html('');
		//  }else{
		//  	errors++;
		// 	$('.errorchangepaswd').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		//  }
		 
		// if(changepassword !== user_confirm_password){
        //   	errors++;
        //     $('.errorchangepaswd').html('<span class="errorspan">Confirm Password does not match</span>');
        // }
		
		// }
		


		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){
    	  /* if(Props.userdetails !== this.props.userdetails){
    		if(Object.keys(Props.userdetails).length > 0){
				this.setState({Loading:false});
					//console.log(Props.carlist);
					if(Props.userdetails[0].status === "success"){

						const formdata = Props.userdetails[0].userlist;
						
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({username:formdata.username});
						this.setState({firstname:formdata.firstname});
						this.setState({lastname:formdata.lastname});
						this.setState({email:formdata.email});
						this.setState({phoneno:formdata.mobileno});
						this.setState({lenderid: formdata.id});
					   
					}
    		}
    	}*/

	
        /* if(Props.useredit !== this.props.useredit){
    		if(Object.keys(Props.useredit).length > 0){
    					this.setState({ Loading: false });

				if(Props.useredit[0].status === "success"){

    			const formdata = Props.useredit[0].userlist;
				console.log(formdata);
				if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
			    this.setState({username:formdata.username});
				this.setState({firstname:formdata.firstname});
				this.setState({lastname:formdata.lastname});
				this.setState({email:formdata.email});
				this.setState({phoneno:formdata.mobileno});
				this.setState({lenderid: formdata.id});
                $('.success_message').html('<div class="status_sucess"><h3>'+ Props.useredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/lender'));
						}
						.bind(this),
						3000
						);
    			} else{
    				 $('.success_message').html('<div class="status_sucess"><h3>'+ Props.useredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    			}
    		}
    	}  */ 
      }




	
  render() {

	const {selectedOption, selectedTIB, selectedYear} = this.state;

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="lenderlist" />  	
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>User Name<span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} disabled="true"/>
					<div className="errorusername"></div>
				</div>
				<div className="form-group">
					<label>Last Name<span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="lastname" onChange={this.handleInputChange} className="form-control" value={this.state.lastname} />
					<div className="errorlastname"></div>
				</div>
				<div className="form-group">
					<label>Phone Number<span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				
				<div className="form-group">
					<label>Company Name<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="text" name="company_name" onChange={this.handleInputChange} className="form-control" value={this.state.company_name} />
					<div className="errorcompany_name"></div>
				</div>
				<div className="form-group">
					<label>Year Started<span class="required" style={{ color: "red" }} > * </span></label>
				    {/* <input type="text" name="year_of_started" onChange={this.handleInputChange} className="form-control" value={this.state.year_of_started} /> */}
					<Select 
							value={selectedYear?selectedYear:''}
								options={lang.common.year_option} 
								onChange={this.handleChange.bind(this, 'year_of_started')}
								/>
					<div className="erroryear_of_started"></div>
				</div>
				<div className="form-group">
					<label>Address <span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="address" onChange={this.handleInputChange} className="form-control" value={this.state.address} />
					<div className="erroraddress"></div>
				</div>
				
				{/*<div className="form-group">
					<label>Reset password:</label>
				    <input type="text" name="changepassword" onChange={this.handleInputChange} className="form-control" value={this.state.chnagepassword}/>
					<div className="errorchangepaswd"></div>
				</div>*/}

<div className="form-group">
					<label>Rating:</label>
				    <input type="number" name="rating" onChange={this.handleInputChange} className="form-control" value={this.state.rating} />
					<div className="errorrating"></div>
				</div>
				
				
				  
				  <div className="default-font">
					<div className="form-group">					
						<label>Status<span class="required" style={{ color: "red" }} > * </span></label>
							<Select 
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
								options={lang.common.status_option} 
								onChange={this.handleChange.bind(this, 'status')}
								/>
					</div>
					</div>
				
				   
				</div>
			<div className="form-right">
				<div className="form-group">
					<label>First Name<span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				<div className="form-group eye-pad">
                    <label>Reset Password</label>
                    <input type={this.state.new_pass_type}  name="changepassword" placeholder="Enter new password" className="form-control" value={this.state.changepassword} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
                 </div>
                  <div className="errorpassword"></div>
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
					<div className="erroremail"></div>
				</div>
				<div className="form-group">
					<label>Website <span class="required" style={{ color: "red" }} > * </span></label>
				    <input type="text" name="website" onChange={this.handleInputChange} className="form-control" value={this.state.website} />
					<div className="errorwebsite"></div>
				</div>
				
				<div className="form-group">					
					<label>Time in Business<span class="required" style={{ color: "red" }} > * </span></label>
						<Select 
						value={selectedTIB?selectedTIB:''}
							options={lang.common.businessTime} 
							onChange={this.handleChange.bind(this, 'tib')}
							/>
							<div className="errortib"></div>
				</div>
				
				<div className="form-group">
					<label>Previous Funded Count:</label>
				    <input type="number" name="previous_funded" onChange={this.handleInputChange} className="form-control" value={this.state.previous_funded} />
					<div className="errorprevious_funded"></div>
				</div>
				
				<div className="form-group">
					<label>Reviews </label>
				    <input type="number" name="review" onChange={this.handleInputChange} className="form-control" value={this.state.review} />
					<div className="errorreview"></div>
				</div>
				{/* <div className="form-group eye-pad">
                    <label>Confirm Password</label>
                    <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleInputChange.bind(this)}/>
                    <span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
                  </div>
                   <div className="errorchangepaswd"></div> */}
				</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


/*const mapStateTopProps = (state) => {
  return {
  	userdetails    : state.userdetails,
  	useredit     : state.useredit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  	 getUserDetailList: (lenderid) =>{
       dispatch({ type: GET_USERDETAILLIST,lenderid });
    },
    getEditUser: (formPayload) =>{
       dispatch({ type: GET_EDITUSER, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));
*/
export default (withRouter(Edit));