import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, categoryURL, baseUrl} from'../Config/Config';
import axios from 'axios';


//import { GET_BROKERLIST, GET_DELETEBROKER, GET_ACTIVATEBROKER, GET_EXPORTBROKER, GET_STRIPELINKTOBROKER } from '../../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      brokerlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:''
    };
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id
      };
     // this.props.getBrokerList(qs.stringify(postobject));

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
		//var qs = require('qs');
		  var admin_id = localStorage.getItem("admin_id");
		  var postobject = {
		  activePage: 1,
		  search_all: '',
		  admin_id: admin_id
		  };
		  
		  this.myBrokerList(postobject);
	    
   }
   
   

   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});

      //var qs = require('qs');

      var admin_id = localStorage.getItem("admin_id");

      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
      //this.props.getBrokerList(qs.stringify(postobject))
	  this.myBrokerList(postobject);
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
         // var qs = require('qs');
          const formPayload = this.state;
           var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
            activePage   : 1,
            search_all : formPayload.search_all,
            admin_id: admin_id         
          };
          //console.log(postObject);
         // this.props.getBrokerList(qs.stringify(postObject));
		 this.myBrokerList(postObject);
        
    } 

   componentWillReceiveProps(Props){
   
    /*if (Object.keys(Props.brokerlist).length > 0) {
      if (Props.brokerlist[0]["status"] === "success") {
     
         this.setState({ totalRecord : Props.brokerlist[0].records_count, brokerlist: Props.brokerlist[0]["brokerlist"] }, function() {
            this.brokerlist();
          });

      }
    }*/
     if(Props.brokerstripelink !== this.props.brokerstripelink){
		  if (Props.brokerstripelink[0]["status"] === "success") {
            $('.success_message').html('<div class="status_sucess"><h3>Email Sent Successfully.</h3></div>');
			setTimeout(
			function() {
				$('.success_message').html('');
			}
			.bind(this),
			5000
			);

      }
	 }
	if(Props.brokerstatus !== this.props.brokerstatus){
      if (Props.brokerstatus[0]["status"] === "success") {
         // var qs = require('qs');
          var admin_id = localStorage.getItem("admin_id");
          var postobject = {
          activePage: this.state.activePage,
          search_all: '',
          admin_id: admin_id
          };
          //this.props.getBrokerList(qs.stringify(postobject));
		  this.myBrokerList(postobject);
      }
    }
	if(Props.exportbroker !== this.props.exportbroker){
		
      if (Props.exportbroker[0]["status"] === "success") {
		  let url =baseUrl+"/"+Props.exportbroker[0]["message"];
          window.open(url,
  '_blank' // <- This is what makes it open in a new window.
);
      }
    }
	if (Props.brokerdelete!== this.props.brokerdelete) {
      if (Props.brokerdelete[0]["status"] === "success") {
          this.setState({
          brokerlist: Props.brokerdelete[0]["brokerlist"],
          });
          }
          window.location.reload(); 
      }
	  
   }


myBrokerList = (postobject) => {
	   var qs = require('qs');
	   axios.post(apiUrl+"broker/list",qs.stringify(postobject)).then(res => {
		   console.log(res.data.brokerlist,'brokerlist')
			//if(res.data.status === 'success'){
         
				this.setState({ totalRecord : res.data.records_count, brokerlist: res.data.brokerlist }, function() {
				this.brokerlist();
			  });
	  
			//}
      }); 
   }
   
activateuser(id, status){
	let stat = ''
		if(status == 'Inactive'){
			stat = "A";
		}else{
			stat = "I";
		}
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          broker_id  :id,
		      broker_status: stat
        };
     // this.props.getActivateBroker(qs.stringify(postObject));
	  axios.post(apiUrl+"broker/change_status",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          window.location.reload(false);
		  //this.myBrokerList(postObject);
        }
      }); 
}
send_accountlink_user(id){
	let stat = ''
		var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :id		     
        };
     // this.props.getStripelinktoBroker(qs.stringify(postObject));
	  axios.post(apiUrl+"broker/generate_stripe_connective_link",qs.stringify(postObject)).then(res => {
		if(res.data.status === 'success'){

			
		}
	  }); 
}

exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
      //this.props.getExportBroker(qs.stringify(postObject));
	 /* axios.post(apiUrl+"broker/export",qs.stringify(postObject)).then(res => {
		if(res.data.status === 'success'){

			
		}
	  });*/ 
}
deletebroker(id){ console.log(id);
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          broker_id  :id,
          admin_id  : admin_id,
		   activePage: this.state.activePage,
			search_all: this.state.search_all,
        };
     // this.props.getDeleteBroker(qs.stringify(postObject));
	  axios.post(apiUrl+"broker/delete",qs.stringify(postObject)).then(res => {
		if(res.data.status === 'success'){

			
		}
	  }); 
}
  brokerlist() {
    var brokerlist = this.state.brokerlist;	
    if (brokerlist != "undefined" && brokerlist != null) {
     
        if (Object.keys(brokerlist).length > 0) {
          const userlistDetails = brokerlist.map(
            (brokerlist, Index) => {				
              let sno = Index+1;
              if(brokerlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={brokerlist.id}>
                <td>{sno}</td>
			         	 <td>
                  {brokerlist.username}
                  </td>
                  <td>
                  {brokerlist.firstname} {brokerlist.lastname}
                  </td>
                 
                  <td>{brokerlist.email}</td>
				          <td>{brokerlist.mobileno}</td>
                  <td>{status}</td>
				   {/* <td>{brokerlist.payment_type}</td> */}
                  <td>
                  {(brokerlist.broker_verify == 'A'?<i className="fa fa-check" aria-hidden="true"></i>:<i className="fa fa-times" aria-hidden="true"></i>)}          
                  </td>
                  <td className="actiongroup">
				 
				  {(brokerlist.payment_type === 'Bank' &&  brokerlist.broker_verify == 'A') && <Link onClick={this.send_accountlink_user.bind(this, brokerlist.id)} className="" title="Connect Stripe"><i className="fa fa-link" aria-hidden="true"></i></Link>}
				  
				  <Link to={{ pathname: '/editbroker/'+brokerlist.id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
				  
                      {(status == 'Active'?<Link onClick={this.activateuser.bind(this, brokerlist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activateuser.bind(this, brokerlist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>)}          
						  {/*<Link onClick={this.deletebroker.bind(this,brokerlist.id)} className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>*/}
                  </td>        
                </tr>
              );
            }
          );
          return userlistDetails;
        }
      
    } else {
        return (
          <tr>
            <td colspan="10" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="brokerlist" />  

  
  <div className="content"> 
    <div className="content-wrapper">
	<div className="success_message"></div>
    <div className="car-listing-wrap">

        <div className="listing-header"> 
          <div className="title">
            <h3>Brokers</h3>
          </div> 
           <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
          <div className="add_button">  
		  {/*<a className="" href='/broker-import'>Import &nbsp;|&nbsp;</a>*/}
			  {/*<a className="" href='#'>Import &nbsp;|&nbsp;</a>
		  
 <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="fa fa-download" aria-hidden="true"></i></Link>
		  */}
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			    <th>User Name</th>
                <th>Name</th>
                <th>Email</th>
				        <th>Contact</th>
                <th>Status</th>
				{/* <th>Payment Type</th> */}
                <th>Verified by Admin</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.brokerlist()}</tbody>
          </Table>
           <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

/*const mapStateTopProps = (state) => {
  return {
  brokerlist: state.brokerlist,
  brokerstatus:state.brokerstatus,
  exportbroker: state.exportbroker,
  brokerdelete: state.brokerdelete,
  brokerstripelink: state.brokerstripelink
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBrokerList: (formVal) =>{
       dispatch({ type: GET_BROKERLIST, formVal});
    },
    getActivateBroker: (formPayload)=> {
      dispatch({ type: GET_ACTIVATEBROKER, formPayload });
    },
	getStripelinktoBroker: (formPayload)=> {
      dispatch({ type: GET_STRIPELINKTOBROKER, formPayload });
    },
   getExportBroker: (formPayload) => {
     dispatch({ type: GET_EXPORTBROKER, formPayload });
	},
	getDeleteBroker: (formPayload) => { console.log(formPayload);
      dispatch({type: GET_DELETEBROKER, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));*/
export default (withRouter(List));