import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';


import {GET_ADDLOANCONFIG }  from '../../actions'; 

const businessTime = [
	{ value: '6', label: 'Less than 6 months' },
	{ value: '12', label: '6-12 months' },
	{ value: '24', label: '1 year' },
	{ value: '60', label: '2-3 years' },
	{ value: '90', label: '3-5 years' },
	{ value: '120', label: 'More than 5 years' }
  ];

 const annualRevenue = [
	{ value: '99999', label: 'Under $100,000' },
	{ value: '175000', label: '$200,000 - $250,000' },
	{ value: '375000', label: '$200,000 - $500,000' },
	{ value: '625000', label: '$500,000 - $750,000' },
	{ value: '875000', label: '$750,000 - $1,000,000' },
	{ value: '2000000', label: '$1,000,000 - $3,000,000' },
	{ value: '3000000', label: '$3,000,000+' }
   ];

 const loanDregrees = [
	{ value: 'Masters', label: 'Masters' },
	{ value: 'MBA', label: 'MBA' },
	{ value: 'PhD', label: 'PhD' },
	{ value: 'JD', label: 'JD' },
	{ value: 'Bachelors', label: 'Bachelors' },
	{ value: 'MD', label: 'MD' },
	{ value: 'DDS', label: 'DDS' },
	{ value: 'LL.M', label: 'LL.M' },
	{ value: 'Associates', label: 'Associates' },
	{ value: 'Bootcamp', label: 'Bootcamp' },
	{ value: 'Other', label: 'Other' }
  ];

const gpaOptions = [
	{ value: '4', label: '4' },
	{ value: '3.5', label: '3.9 to 3.5' },
	{ value: '3', label: '3.5 to 3.0' },
	{ value: '2', label: '3.0 to 2.5' },
	{ value: '2.5', label: '2.5 to 2.0' },
	{ value: '2', label: '2.0 to 1.5' },
	{ value: '1.5', label: '1.5 to 1.0' },
	{ value: '1', label: '1.0 to 0' }


];

const interestRateOption = [
	{ value: '1.18', label: '1.18% - 1.25%' },
	{ value: '1.25', label: '1.25% - 1.35%' },
	{ value: '1.35', label: '1.35% - 1.45%' }
];

const termOption = [
	{ value: '12', label: '12-24 Months' },
	{ value: '8', label: '8-18 Months' },
	{ value: '6', label: '6-9 Months' }
];

// const creditScoreOption = [
// 	{ value: '300', label: '300-579' },
// 	{ value: '580', label: '580-669' },
// 	{ value: '670', label: '670-739' },
// 	{ value: '740', label: '740-799' },
// 	{ value: '800', label: '800-850' }
// ];

export const creditScoreOption = [
	{ value: '750-850', label: 'Above 750' },
	{ value: '720-750', label: '720-750' },
	{ value: '670-720', label: '670-720' },
	{ value: '650-670', label: '650-670' },
	{ value: '620-650', label: '620-650' },
	{ value: '600-620', label: '600-620' },
	{ value: '550-600', label: '550-600' },
	{ value: '500-549', label: 'Below 550' }
  ];

const ratingsOption = [
	{ value: 'A', label: 'A' },
	{ value: 'B', label: 'B' },
	{ value: 'C', label: 'C' },
	{ value: 'D', label: 'D' }
];

class Add extends Component {

	 
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			
			above_revenue_amount:'',
			rating:'',
			
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue : 'active',
			Loading:false
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }
	

    handleChange = selectedOption => {
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Rating Configuration Add');
		$('.student_loan').hide();
		this.businessType();
		this.businessCategories();
		this.loanTypes();
    }

	businessType = () => {
		axios.get(apiUrl+'businesstypes/dropdown_list').then(res => {
			if(res.data.status === "success"){				
				this.setState({					
					businessTypeOption:res.data.bt_dd_obj
				});
			 }				 
			 
	   });	
	}

	businessCategories = () => {
		axios.get(apiUrl+'businesscategories/dropdown_list').then(res => {
			if(res.data.status === "success"){				
				this.setState({					
					businessCategoryOption:res.data.bc_dd_obj
				});
			 }				 
			 
	   });	
	}

	loanTypes = () => {
		axios.get(apiUrl+'loantypes/dropdown_list').then(res => {
			if(res.data.status === "success"){				
				this.setState({					
					loanTypesOption:res.data.lt_dd_obj
				});
			 }				 
			 
	   });	
	}


        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					loan_type:formPayload.loanType,
					business_category:formPayload.businessCategory,
					business_type:formPayload.businessType,
					time_in_business:formPayload.businessTime,
					annual_revenue:formPayload.annualRevenue,
					rating:formPayload.rating,
					type_of_degree:formPayload.degreeType,
					gpa:formPayload.gpa,
					interest_rate:formPayload.interestRate,
					term:formPayload.term,
					credit_score:formPayload.creditScore,
					// status: formPayload.selectedvalue

				};
				console.log(postObject, 'post_obj');
				//this.props.getAddCompany(qs.stringify(postObject));
				
				axios.post(apiUrl+"annualRevenueconfig/add_rating",qs.stringify(postObject)).then(res => {
					this.setState({Loading:false});
					if(res.data.status === "success"){		
					
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						this.setState({
							
							above_revenue_amount:'',
							rating:'',
						});
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/ratingCalculation'));
						}
						.bind(this),
						3000
						);
					 }else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					 }				 
					 
    	       });	
			}
	}
	handlePriceChange = (e) => {
		
		const inputValue = e.target.value;
		alert(inputValue)
		// You can format the input value with a currency symbol here
		if(inputValue!=''){
		const formattedPrice = inputValue.replace(/[^\d.]/g, '');

	    const priceWithSymbol = `$${formattedPrice}`;
	   

	
		this.setState({
			above_revenue_amount: priceWithSymbol
		  });
		}
	  };




	validateForm() {
		
		const {rating,annualRevenue,businessType,businessTime,interestRate,term,creditScore} = this.state;
		let errors = {};
      	let formIsValid = true;


		if (!interestRate) {
			formIsValid = false;
			$('.errorinterest_rate').html('<span class="errorspan">Please select the interest rate.</span>');
		}else {
			$('.errorinterest_rate').html('');
		}

		if (!creditScore) {
			formIsValid = false;
			$('.errorcredit_score').html('<span class="errorspan">Please select the credit score.</span>');
		}else {
			$('.errorcredit_score').html('');
		}


		
		if (!term) {
			formIsValid = false;
			$('.errorterm').html('<span class="errorspan">Please select the term.</span>');
		}else{
			$('.errorterm').html('');
		}

			if (!businessType) {
				formIsValid = false;
				$('.errorbusiness_type').html('<span class="errorspan">Please select the business category.</span>');
			}else if(businessType){
				$('.errorbusiness_type').html('');
			}

			if (!businessTime) {
				formIsValid = false;
				$('.errortime_in_business').html('<span class="errorspan">Please select the time of business.</span>');
			}else if(businessTime){
				$('.errortime_in_business').html('');
			}
		
		
		
			if (!annualRevenue) {
				formIsValid = false;
				$('.errorannual_revenue').html('<span class="errorspan">Please select the annual revenue</span>');
			}else if(annualRevenue){
					$('.errorannual_revenue').html('');
			}
		
		

		if (!rating) {
			formIsValid = false;
			$('.errorrating').html('<span class="errorspan">Please enter the rating.</span>');
		}else if(rating){
				$('.errorrating').html('');
		}

		

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

	setValues = (v,action) =>{ 
   
		this.setState({
			 [action]: v.value
		 });

		 
	}

	handleMultiSelect = (newValue: any, actionMeta: any) => {
		const newValuesArr = newValue ? newValue.map(item => item.value) : [];
		if(actionMeta.name === "businessType"){
			this.setState({ businessType: newValuesArr });
		}else if(actionMeta.name === "degreeType"){
			this.setState({ degreeType: newValuesArr });
		}
		
	  };

	
  render() {
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="ratingCalculation" />  

	
	<div className="content">	
		<div className="content-wrapper">
				  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Rating Calculation</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				
				
				
			<div className="form-group z-index-999">					
					<label>Rating<span class="required" style={{ color: "red" }} > * </span></label>
					<Select className="fund-app-drop"  placeholder="Rating" options={ratingsOption} onChange={(values) => this.setValues(values,'rating')}   name="rating" isSearchable={ false }  />
					<div className="errorrating"></div>
				</div>
				{/* <div className="form-group"> 
					<label>Annual Amount<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="number"  name="above_revenue_amount" onChange={this.handleInputChange} className="form-control" value={this.state.above_revenue_amount} />
					<div className="errorabove_revenue_amount"></div>
				</div>*/}

				{/* <div className="default-font">
					<div className="form-group">					
						<label>Loan Type<span class="required" style={{ color: "red" }} > * </span></label>
								<Select className="fund-app-drop"  placeholder="Loan Type" options={this.state.loanTypesOption} onChange={(values) => this.setValues(values,'loanType')}   name="loanType" isSearchable={ false }  />
								<div className="errorloan_type"></div>
					</div>
				</div> */}

				<div className="default-font business_loan">
					<div className="form-group z-index-998">					
						<label>Business Type<span class="required" style={{ color: "red" }} > * </span></label>
							
								<Select className="fund-app-drop" isMulti placeholder="Business Type" options={this.state.businessTypeOption} onChange={this.handleMultiSelect}  name="businessType" isSearchable={ false }  />
								<div className="errorbusiness_type"></div>
					</div>
				</div>	


				<div className="default-font business_loan">
					<div className="form-group z-index-997">					
						<label>Interest Rate<span class="required" style={{ color: "red" }} > * </span></label>
						
								<Select className="fund-app-drop" placeholder="Interest Rate" options={interestRateOption} onChange={(values) => this.setValues(values,'interestRate')}  name="interestRate" isSearchable={ false }  />
								<div className="errorinterest_rate"></div>
					</div>
				</div>

				

				<div className="default-font business_loan">
					<div className="form-group z-index-996">					
						<label>Credit Score<span class="required" style={{ color: "red" }} > * </span></label>
						
								<Select className="fund-app-drop" placeholder="Interest Rate" options={creditScoreOption} onChange={(values) => this.setValues(values,'creditScore')}  name="creditScore" isSearchable={ false }  />
								<div className="errorcredit_score"></div>
					</div>
				</div>
				
				<div className="default-font student_loan">
					<div className="form-group z-index-995">					
						<label>GPA<span class="required" style={{ color: "red" }} > * </span></label>
							
								<Select className="fund-app-drop" placeholder="GPA" options={gpaOptions} onChange={(values) => this.setValues(values,'gpa')}  name="gpa" isSearchable={ false }  />
								<div className="errorgpa"></div>
					</div>
				</div>

			</div>
			<div className="form-right">

				
				{/* <div className="default-font business_loan">
					<div className="form-group">					
						<label>Business Category<span class="required" style={{ color: "red" }} > * </span></label>
								<Select className="fund-app-drop" placeholder="Business Category" options={this.state.businessCategoryOption}  onChange={(values) => this.setValues(values,'businessCategory')}  name="businessCategory" isSearchable={ false }  />
								<div className="errorbusiness_category"></div>
					</div>
				</div>	 */}

				<div className="form-group z-index-999 annual_revenue">					
					<label>Annual Revenue<span class="required" style={{ color: "red" }} > * </span></label>
					<Select className="fund-app-drop" placeholder="Annual Revenue" options={annualRevenue} onChange={(values) => this.setValues(values,'annualRevenue')}  name="businessTime" isSearchable={ false }  />
					<div className="errorannual_revenue"></div>
				</div>


				


				<div className="default-font business_loan">
					<div className="form-group z-index-998">					
						<label>Time in Business<span class="required" style={{ color: "red" }} > * </span></label>
						
								<Select className="fund-app-drop" placeholder="Time in Business" options={businessTime} onChange={(values) => this.setValues(values,'businessTime')}  name="businessTime" isSearchable={ false }  />
								<div className="errortime_in_business"></div>
					</div>
				</div>
				

				<div className="form-group z-index-997 annual_revenue">					
					<label>Term<span class="required" style={{ color: "red" }} > * </span></label>
					<Select className="fund-app-drop" placeholder="Term" options={termOption} onChange={(values) => this.setValues(values,'term')}  name="term" isSearchable={ false }  />
					<div className="errorterm"></div>
				</div>

				<div className="default-font student_loan">
					<div className="form-group z-index-996">					
						<label>Type of Degree<span class="required" style={{ color: "red" }} > * </span></label>
							{/* <Select 
								options={lang.common.status_option} 
								onChange={(values) => this.setValues(values,'status')} 
								/> */}
								<Select className="fund-app-drop" isMulti placeholder="Type of Degree" options={loanDregrees} onChange={this.handleMultiSelect}  name="degreeType" isSearchable={ false }  />
								<div className="errortype_of_degree"></div>
					</div>
				</div>
					

				
			</div>	
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


/*const mapStateTopProps = (state) => {
  return {
  	companyadd: state.companyadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getAddCompany: (formPayload) =>{
       dispatch({ type: GET_ADDLOANCONFIG, formPayload});
    }
  }
}*/

export default (withRouter(Add));