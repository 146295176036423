import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import { lang } from '../Helpers/lang';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, categoryURL, baseUrl} from'../Config/Config';
import axios from 'axios';


//import { GET_BROKERLIST, GET_DELETEBROKER, GET_ACTIVATEBROKER, GET_EXPORTBROKER, GET_STRIPELINKTOBROKER } from '../../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      lenderlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:'',
      sno_index:0,
    };
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id
      };
     // this.props.getlenderList(qs.stringify(postobject));

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
		//var qs = require('qs');
		  var admin_id = localStorage.getItem("admin_id");
		  var postobject = {
		  activePage: 1,
		  search_all: '',
		  admin_id: admin_id
		  };
		  
		  this.mylenderList(postobject);
	    
   }
   
   

   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var sno_index = pageNumber - 1;
      this.setState({sno_index: sno_index});

      //var qs = require('qs');

      var admin_id = localStorage.getItem("admin_id");

      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
      //this.props.getlenderList(qs.stringify(postobject))
	  this.mylenderList(postobject);
    }

    handleInputChange(event) {
      var admin_id = localStorage.getItem("admin_id");
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    var postobject = {
      activePage: 1,
      search_all: value,
      admin_id: admin_id
    };
   
  this.mylenderList(postobject);
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
         // var qs = require('qs');
          const formPayload = this.state;
           var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
            activePage   : 1,
            search_all : formPayload.search_all,
            admin_id: admin_id         
          };
          //console.log(postObject);
         // this.props.getlenderList(qs.stringify(postObject));
		 this.mylenderList(postObject);
        
    } 

   componentWillReceiveProps(Props){
   
    /*if (Object.keys(Props.lenderlist).length > 0) {
      if (Props.lenderlist[0]["status"] === "success") {
     
         this.setState({ totalRecord : Props.lenderlist[0].records_count, lenderlist: Props.lenderlist[0]["lenderlist"] }, function() {
            this.lenderlist();
          });

      }
    }*/
     if(Props.lenderstripelink !== this.props.lenderstripelink){
		  if (Props.lenderstripelink[0]["status"] === "success") {
            $('.success_message').html('<div class="status_sucess"><h3>Email Sent Successfully.</h3></div>');
			setTimeout(
			function() {
				$('.success_message').html('');
			}
			.bind(this),
			5000
			);

      }
	 }
	if(Props.lenderstatus !== this.props.lenderstatus){
      if (Props.lenderstatus[0]["status"] === "success") {
         // var qs = require('qs');
          var admin_id = localStorage.getItem("admin_id");
          var postobject = {
          activePage: this.state.activePage,
          search_all: '',
          admin_id: admin_id
          };
          //this.props.getlenderList(qs.stringify(postobject));
		  this.mylenderList(postobject);
      }
    }
	if(Props.exportlender !== this.props.exportlender){
		
      if (Props.exportlender[0]["status"] === "success") {
		  let url =baseUrl+"/"+Props.exportlender[0]["message"];
          window.open(url,
  '_blank' // <- This is what makes it open in a new window.
);
      }
    }
	if (Props.lenderdelete!== this.props.lenderdelete) {
      if (Props.lenderdelete[0]["status"] === "success") {
          this.setState({
          lenderlist: Props.lenderdelete[0]["lenderlist"],
          });
          }
          window.location.reload(); 
      }
	  
   }


mylenderList = (postobject) => {
	   var qs = require('qs');
	   axios.post(apiUrl+"lender/list",qs.stringify(postobject)).then(res => {
		  // console.log(res.data.lenderlist,'lenderlist')
			if(res.data.status === 'success'){
         
				this.setState({ totalRecord : res.data.records_count, lenderlist: res.data.lenderlist }, function() {
				this.lenderlist();
			  });
	  
			}else{
        this.setState({ totalRecord :0, lenderlist: null }, function() {
          this.lenderlist();
          });
      }
      }); 
   }
   
activateuser(id, status){
	let stat = ''
  if(this.state.selectedStatus ==='inactive'){
    stat = "I";
  }else{
    stat = "A";
  }  

     
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          lender_id  :this.state.selectid,
		      lender_status: stat
        };
     // this.props.getActivatelender(qs.stringify(postObject));
	  axios.post(apiUrl+"lender/change_status",qs.stringify(postObject)).then(res => {
        
			
      if(res.data.status === 'success'){
        var postobject1 = {
          activePage: this.state.activePage,
          search_all: '',
          admin_id : localStorage.getItem("admin_id")
        };
        this.mylenderList(postobject1);
        $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
        setTimeout(
          function() {
         
            $('.success_message').html('');
             
          }
          .bind(this),
          3000
          );
          
    }
		});
}
send_accountlink_user(id){
	let stat = ''
		var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :id		     
        };
     // this.props.getStripelinktolender(qs.stringify(postObject));
	  axios.post(apiUrl+"lender/generate_stripe_connective_link",qs.stringify(postObject)).then(res => {
		if(res.data.status === 'success'){

			
		}
	  }); 
}

exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
      //this.props.getExportlender(qs.stringify(postObject));
	 /* axios.post(apiUrl+"lender/export",qs.stringify(postObject)).then(res => {
		if(res.data.status === 'success'){

			
		}
	  });*/ 
}
deletelender(id){ console.log(id);
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          lender_id  :id,
          admin_id  : admin_id,
		   activePage: this.state.activePage,
			search_all: this.state.search_all,
        };
     // this.props.getDeletelender(qs.stringify(postObject));
	  axios.post(apiUrl+"lender/delete",qs.stringify(postObject)).then(res => {
		if(res.data.status === 'success'){

			
		}
	  }); 
}
confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activateuser();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}

  lenderlist() {
    var lenderlist = this.state.lenderlist;	
    var sno_index = this.state.sno_index;
    if (lenderlist != "undefined" && lenderlist != null) {
     
        if (Object.keys(lenderlist).length > 0) {
          const userlistDetails = lenderlist.map(
            (lenderlist, Index) => {	
              if(sno_index > 0){ Index = (sno_index * 10) + Index; }              			
              let sno = Index+1;
              if(lenderlist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }
              return (
               
                <tr key={lenderlist.id}>
                <td>{sno}</td>
                <td>
                  {lenderlist.firstname} {lenderlist.lastname}
                  </td>
                  <td>
                  {lenderlist.company_name}
                  </td>
                 
			         	 <td>
                  {lenderlist.username}
                  </td>
                  <td>
                  {lenderlist.org_password}
                  </td>
                 
                  {/* <td>{lenderlist.email}</td> */}
				          <td>{lenderlist.mobileno}</td>
                  <td>{lang.Capitalize(status)}</td>
				   {/* <td>{lenderlist.payment_type}</td> */}
                  {/* <td>
                  {(lenderlist.verified == 'A'?<i className="fa fa-check" aria-hidden="true"></i>:<i className="fa fa-times" aria-hidden="true"></i>)}          
                  </td> */}
                  <td className="actiongroup">
				 
				  {(lenderlist.payment_type === 'Bank' &&  lenderlist.verified == 'A') && <Link onClick={this.send_accountlink_user.bind(this, lenderlist.id)} className="" title="Connect Stripe"><i className="fa fa-link" aria-hidden="true"></i></Link>}
				  
				  <Link to={{ pathname: '/editlender/'+lenderlist.id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
				  
                      {(status == 'active'?<Link onClick={this.confirmPopup.bind(this, lenderlist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, lenderlist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>)}          
						  {/*<Link onClick={this.deletelender.bind(this,lenderlist.id)} className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>*/}
                  </td>        
                </tr>
              );
            }
          );
          return userlistDetails;
        }
      
    } else {
        return (
          <tr>
            <td colspan="10" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the lender?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="lenderlist" />  

  
  <div className="content"> 
    <div className="content-wrapper">
	<div className="success_message"></div>
    <div className="car-listing-wrap">

        <div className="listing-header"> 
        <div className="success_message"></div>
          <div className="title">
            <h3>Lenders</h3>
          </div> 
           <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
          <div className="add_button">  
		  {/*<a className="" href='/lender-import'>Import &nbsp;|&nbsp;</a>*/}
			  {/*<a className="" href='#'>Import &nbsp;|&nbsp;</a>
		  
 <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="fa fa-download" aria-hidden="true"></i></Link>
		  */}
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Company</th>

			          <th>User Name</th>
                <th>Password</th>
				        <th>Contact</th>
                <th>Status</th>
				{/* <th>Payment Type</th> */}
                {/* <th>Verified</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.lenderlist()}</tbody>
          </Table>
           <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

/*const mapStateTopProps = (state) => {
  return {
  lenderlist: state.lenderlist,
  lenderstatus:state.lenderstatus,
  exportlender: state.exportlender,
  lenderdelete: state.lenderdelete,
  lenderstripelink: state.lenderstripelink
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getlenderList: (formVal) =>{
       dispatch({ type: GET_lenderLIST, formVal});
    },
    getActivatelender: (formPayload)=> {
      dispatch({ type: GET_ACTIVATElender, formPayload });
    },
	getStripelinktolender: (formPayload)=> {
      dispatch({ type: GET_STRIPELINKTOlender, formPayload });
    },
   getExportlender: (formPayload) => {
     dispatch({ type: GET_EXPORTlender, formPayload });
	},
	getDeletelender: (formPayload) => { console.log(formPayload);
      dispatch({type: GET_DELETElender, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));*/
export default (withRouter(List));