import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import { lang } from '../Helpers/lang';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';
import { apiUrl, categoryURL} from'../Config/Config';
import axios from 'axios';


class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          userlist:'',
          editable: false,
          priorityid:'',
          priorityvalue:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          sno_index:0,
      };
    
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
	    var qs = require('qs');
		  var postobject = {
		  activePage: 1,
		  search_all: '',
		  admin_id: localStorage.getItem('admin_id')  
		  };
	   this.myuserlist(postobject);
   }


  handlePageChange(pageNumber) {
   // console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
    var sno_index = pageNumber - 1;
    this.setState({sno_index: sno_index});

    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
  
	this.myuserlist(postobject);
  }

  handleInputChange(event) {
	  const {name, value} = event.target;      
	  this.setState({
		[name]: value
	  });
    var postobject = {
      activePage: 1,
      search_all: value,
      admin_id: localStorage.getItem('admin_id')  
    };
    this.myuserlist(postobject);
	}
	
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : 1,
          search_all : formPayload.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        //console.log(postObject);
         this.myuserlist(postObject);
      
  } 

   componentWillReceiveProps(Props){
   
    
	
	
   }

deleteuser(id){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :id
        };
    //  this.props.getDeleteUser(qs.stringify(postObject));
	  axios.post(apiUrl+"user/delete",qs.stringify(postObject)).then(res => {	   
  
			if(res.data.status === "success") {					
					var postobject1 = {
						activePage: this.state.activePage,
						search_all: '',
						admin_id : localStorage.getItem("admin_id")
					};
					this.myuserlist(postobject1);
			}
		});
}

activateuser(){
	let stat = ''
		if(this.state.selectedStatus ==='inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :this.state.selectid,
		  user_status: stat
        };
      //this.props.getActivateUser(qs.stringify(postObject));
		axios.post(apiUrl+"user/change_status",qs.stringify(postObject)).then(res => {	   
  
			
      if(res.data.status === 'success'){
        var postobject1 = {
          activePage: this.state.activePage,
          search_all: '',
          admin_id : localStorage.getItem("admin_id")
        };
        this.myuserlist(postobject1);
        $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
        setTimeout(
          function() {
         
            $('.success_message').html('');
             
          }
          .bind(this),
          3000
          );
          
    }
		});
		
	   
}

exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
     // this.props.getExportUser(qs.stringify(postObject));
	axios.post(apiUrl+"user/export",qs.stringify(postObject)).then(res => { 
    if(res.data.status === 'success'){
      var url = res.data.url;
      var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
      window.open(cleanurl, '_blank', 'noreferrer');
      // window.location.reload();
    }
		else{
			console.log('Exporterror')
		}
	});
	
}
confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activateuser();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}

myuserlist = (postobject) =>{
	 var qs = require('qs');
	 axios.post(apiUrl+"user/list",qs.stringify(postobject)).then(res => {
    
	  if(res.data.status === "success") {
   
		 this.setState({ totalRecord : res.data.records_count, userlist: res.data.userlist }, function() {
            this.userlist();
	   });
    }else{
      
      this.setState({ totalRecord :0, userlist: null }, function() {
        this.userlist();
 })
    }
	});
}

  userlist() {
   
    var userlistNew = this.state.userlist;//this.props.userlist[0];
    var sno_index = this.state.sno_index;
    if (userlistNew != "undefined" && userlistNew != null) {
      
        if (Object.keys(userlistNew).length > 0) {
          const userlistDetails = userlistNew.map(
            (userlist, Index) => {
              if(sno_index > 0){ Index = (sno_index * 10) + Index; }              
              let sno = Index+1;
              if(userlist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }

              var mobileno = ((userlist.mobileno == null)||(userlist.mobileno == '')) ?  userlist.mobileno = 'N/A' : userlist.mobileno;
              return (
                <tr key={userlist.id}>
                <td>{sno}</td>
				 <td>
                  {userlist.username}
                  </td>
                  <td>
                  {userlist.firstname}
                  </td>
                  <td>{userlist.org_password}</td>
                  {/* <td>{userlist.domain_name}</td>*/}
                  <td>{userlist.email}</td>
                  
				  {/*      <td>{mobileno}</td>*/}
                  {/* <td>{userlist.subscribed_plan_name} ({userlist.user_type})</td> */}
				        
                 {/*  <td>{lang.Capitalize(status)}</td>*/}
                 
                  <td className="actiongroup">
                     <Link to={{ pathname: '/view/'+userlist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                     <Link to={{ pathname: '/edit/'+userlist.id}} className="" title="Duplicate">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                     {(status == 'active'?<Link onClick={this.confirmPopup.bind(this, userlist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, userlist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>)}          
                  </td>
                </tr>
              );
            }
          );
          return userlistDetails;
        }      
    }else {
        return (
          <tr>
            <td colSpan="7" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the user?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="user" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div className="car-listing-wrap">

        <div className="listing-header"> 

          <div className="title">
            <h3>Users</h3>
          </div>
          <div className="success_message"></div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
      		 <a className="" href='/user-add'>Add &nbsp;|&nbsp;</a>
			 {/* <a className="" href='/user-import'>Import <i className="import-icon icon" aria-hidden="true"></i> &nbsp;|&nbsp;</a>*/}
           <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="export-icon icon" aria-hidden="true"></i></Link>
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			          <th>Username</th>
                <th>Name</th>
                <th>Password</th>
                {/* <th>Domain</th>*/}
                <th>Email</th> 
				{/* <th>Contact</th>*/}
               {/*<th>Domain</th>*/}
               {/* <th>User Type</th> 
                <th>Status</th>*/}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.userlist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

export default (withRouter(List));