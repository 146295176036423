import { SET_ADDCMS } from '../actions';

const cmsadd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDCMS:
      return [...action.value];  
    default: return state;
  }
}

export default cmsadd;
