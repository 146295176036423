import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import CKEditor from 'ckeditor4-react';

import {GET_ADDPACKAGE }  from '../../actions'; 

	
	
const initialState = {
			type:'',
			package_content:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedsizevalue: '',
            selectedsizeOption:{ value: '350x350', label: '350x350'},
			price:'',
			Loading:false,
			admin_id: localStorage.getItem('admin_id')
		};
		
class Add extends Component {
		
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handleReset = this.handleReset.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );
    }
	handleReset() {
         this.setState(initialState);
    }

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };

   handleInputChange(event) {

      const {name, value} = event.target;    
      console.log()
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Package Add');
    }

 onEditorChange( evt ) {
    	console.log(evt.editor.getData())
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            package_content: evt.editor.getData()
        } );
		}, 1000);
     
    }
  

	
	 handleFormSubmit = () => {


			if(this.validateForm()){
				
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
			
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					type: formPayload.type,
					package_content:formPayload.package_content,
					price        : formPayload.price,
					status      :status,
					
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
				
				this.props.getAddPackage(formData,config);
				//this.props.getAddCar(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {type,package_content,price} = this.state;
		let errors = 0;
	
		if (!type) {
			errors++;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(type){
			$('.errorname').html('');
		}


		if (!price) {
			errors++;
			$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
		}else if(price){
			$('.errorprice').html('');
		}

		


		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

    }

    componentWillReceiveProps(Props){

    	 	if(Props.packageadd !== this.props.packageadd){
    		if(Object.keys(Props.packageadd).length > 0){
				this.setState({Loading:false});

					if(Props.packageadd[0].status === "success"){

							$('.success_message').html('<div class="status_sucess"><h3>'+Props.packageadd[0].message+'</h3></div>');
							scrollToTop();
							setTimeout(
							function() {
							$('.success_message').html('');
							//this.handleReset();
							this.props.history.push('/packages');
							}
							.bind(this),
							3000
							);
						}else{
							$('.success_message').html('<div class="status_sucess"><h3>'+Props.packageadd[0].message+'</h3></div>');
							scrollToTop();
							setTimeout(
							function() {
							$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}

    		}
    	}

     }

  render() {
  	let preview = '';
	

  	const {selectedOption, } = this.state;

	

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="packages" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">

			<div className="success_message"></div>
			<div className="title">
				<h4>Add Package</h4>
			</div>
		    <form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Type:</label>
				    <input type="text" name="type" onChange={this.handleInputChange} className="form-control" value={this.state.type} />
					<div className="errorname"></div>
				</div>
				
				<div className="form-group">					
  					<label>Package Description:</label>
 						{/*<textarea type="text" name="package_content" onChange={this.handleInputChange} className="form-control" value={this.state.package_content} />*/}
 				     <CKEditor
					data={this.state.package_content}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/>
  				</div>

			</div>				
			<div className="form-right">
				
		      <div className="form-group">
					<label>Price:</label>
				    <input type="text" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price} />
					<div className="errorprice"></div>
				</div>

				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	packageadd     : state.packageadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAddPackage: (formPayload) =>{
       dispatch({ type: GET_ADDPACKAGE, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));