/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import {  GET_ADDPACKAGE, SET_ADDPACKAGE, GET_PACKAGELIST, SET_PACKAGELIST, GET_EDITPACKAGE, SET_EDITPACKAGE, GET_PACKAGEDETAIL, SET_PACKAGEDETAIL,GET_ACTIVATEPACKAGE, SET_ACTIVATEPACKAGE} from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


export const watchGetAddPackage = function* () {
  yield takeEvery(GET_ADDPACKAGE, workerGetAddPackage);
}

function* workerGetAddPackage({ formPayload }) {
  try {
      
 const result = yield call(getAddPackage, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDPACKAGE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddPackage(formPayload) {
   return Axios.post(apiUrl+'package/add', formPayload);
} 


/*list*/



export const watchGetPackageList = function* () {
  yield takeEvery(GET_PACKAGELIST, workerGetPackageList);
}

function* workerGetPackageList({ formVal }) {
  try {
      
 const result = yield call(getPackageList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_PACKAGELIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getPackageList(formVal) {
   return Axios.post(apiUrl+'package/list', formVal);
} 


/*get Cms detail*/

export const watchGetPackageDetail = function* () {
  yield takeEvery(GET_PACKAGEDETAIL, workerGetPackageDetail);
}

function* workerGetPackageDetail({ packageId }) {
  var url = apiUrl+'package/packagedetail?id='+packageId;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PACKAGEDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  


/*edit the vehicle*/

export const watchGetEditPackage = function* () {
  yield takeEvery(GET_EDITPACKAGE, workerGetEditPackage);
}

function* workerGetEditPackage({ formPayload }) {
  try {
      
 const result = yield call(getEditPackage, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITPACKAGE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditPackage(formPayload) {
   return Axios.post(apiUrl+'package/edit', formPayload);
} 



/*delete the cms*/

export const watchGetDeletePackage = function* () {
  yield takeEvery(GET_DELETEPACKAGE, workerGetDeletePackage);
}

function* workerGetDeletePackage({ formPayload }) {
  try {
      
 const result = yield call(getDeletePackage, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEPACKAGE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeletePackage(formPayload) {
   return Axios.post(apiUrl+'package/delete', formPayload);
} 


/*change status the user*/

export const watchActivatePackage = function* () {
  yield takeEvery(GET_ACTIVATEPACKAGE, workerActivatePackage);
}

function* workerActivatePackage({ formPayload }) {
  try {
      
 const result = yield call(getActivatePackage, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ACTIVATEPACKAGE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getActivatePackage(formPayload) {
   return Axios.post(apiUrl+'package/change_status', formPayload);
} 