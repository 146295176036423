/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_TEMPLATELIST, SET_TEMPLATELIST, GET_TEMPLATEDETAIL,SET_TEMPLATEDETAIL, GET_TEMPLATEADD, SET_TEMPLATEADD, GET_TEMPLATEDELETE, SET_TEMPLATEDELETE,GET_TEMPLATEEDIT, SET_TEMPLATEEDIT} from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


/*list*/



export const watchGetTemplateList = function* () {
  yield takeEvery(GET_TEMPLATELIST, workerGetTemplateList);
}

function* workerGetTemplateList({ formVal }) {
  try {
      
 const result = yield call(getTemplateList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_TEMPLATELIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getTemplateList(formVal) {
   return Axios.post(apiUrl+'template/list', formVal);
} 



export const watchGetTemplateDetail = function* () {
  yield takeEvery(GET_TEMPLATEDETAIL, workerGetTemplateDetail);
}

function* workerGetTemplateDetail({ templateid }) {
        var url = apiUrl+'template/templatedetail?id='+templateid;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_TEMPLATEDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}


/*add the template*/

export const watchGetTemplateAdd = function* () {
  yield takeEvery(GET_TEMPLATEADD, workerGetTemplateAdd);
}

function* workerGetTemplateAdd({ formPayload }) {
  try {
      
 const result = yield call(getTemplateAdd, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_TEMPLATEADD, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getTemplateAdd(formPayload) {
   return Axios.post(apiUrl+'template/add', formPayload);
} 

/*delete the template*/

export const watchGetTemplateDelete = function* () {
  yield takeEvery(GET_TEMPLATEDELETE, workerGetTemplateDelete);
}

function* workerGetTemplateDelete({ formPayload }) {
  try {
      
 const result = yield call(getTemplateDelete, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_TEMPLATEDELETE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getTemplateDelete(formPayload) {
   return Axios.post(apiUrl+'template/delete', formPayload);
} 


/*edit the template*/

export const watchGetTemplateEdit = function* () {
  yield takeEvery(GET_TEMPLATEEDIT, workerGetTemplateEdit);
}

function* workerGetTemplateEdit({ formPayload }) {
  try {
      
 const result = yield call(getTemplateEdit, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_TEMPLATEEDIT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getTemplateEdit(formPayload) {
   return Axios.post(apiUrl+'template/edit', formPayload);
}