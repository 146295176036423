import { SET_PROFILEDETAILS } from '../actions';

const profile = (state = [], action) => {
  switch (action.type) {
	case SET_PROFILEDETAILS:
      return [...action.value];  
    default: return state;
  }
}

export default profile;
