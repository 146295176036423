import { SET_COMPANYLIST } from '../actions';

const companylist = (state = [], action) => {
  switch (action.type) {
	case SET_COMPANYLIST:
      return [...action.value];  
    default: return state;
  }
}

export default companylist;
