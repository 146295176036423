import { SET_ADDUSER } from '../actions';

const useradd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDUSER:
      return [...action.value];  
    default: return state;
  }
}

export default useradd;
